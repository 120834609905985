import React from 'react';

import { useText } from 'common/hooks';

import {
  Header,
  Image,
  ImageWrapper,
  Paragraph,
  SubHeader,
  Wrapper,
  Link,
  Button,
} from './elements';

export default function Kroniken() {
  const t = useText();

  return (
    <Wrapper>
      <Header>{'Krøniken'}</Header>
      <Paragraph>{t('kroniken.firstParagraph')}</Paragraph>
      <ImageWrapper>
        <Image src={'/images/kroniken-header.png'} />
      </ImageWrapper>
      <SubHeader>{t('kroniken.subHeader')}</SubHeader>
      <Paragraph>{t('kroniken.secondParagraph')}</Paragraph>
      <Button href="https://drive.google.com/drive/folders/10cJ19Ge7tPnEjZqYdgNxikZzpCpOZhSk">
        {t('kroniken.submitImages')}
      </Button>
      <SubHeader>{t('kroniken.oldEditions')}</SubHeader>
      {/* <Paragraph>{t('kroniken.thirdParagraph')}</Paragraph> */}
      {/* <Link>{t('kroniken.secondEdition')}</Link> */}
      <Paragraph>{t('kroniken.buy')}</Paragraph>
    </Wrapper>
  );
}
