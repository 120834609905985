export default `MARKDOWN:
Betal medlemsavgiften til:
- Kontonummer: 4200 32 16513 (IBAN: NO5842003216513)
- Vipps: #622814

**Husk å merke betalingen med navnet ditt!**

Når vi har bekreftet betalingen din, er du offisielt medlem av Studentenes Kameraklubb.
Hvis du støter på noen problemer, ikke nøl med å kontakte oss via e-post eller Facebook.
`;
